.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's layered above other elements */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Light shadow for depth */
  z-index: 1100; /* Ensure it's on top of the overlay */
}



/* Modal Container */
.modal {
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Adjust as needed */
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1010; /* Ensure it's above other content */
}


.modal-header {
  background-color: #f0f0f0; /* Light grey background */
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}


.close-button, .copy-button {
  background-color: #6a0dad;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px; /* Space between close and copy buttons */
}

.copy-button {
  background-color: #4CAF50;
  margin-right: 10px; /* Adjusted from 10px to 15px to bump it over by an extra 5px */
}

.copy-button:hover {
  background-color: #45a049;
}

.close-button:hover {
  background-color: #9b45db;
}


/* Modal Content Area */
.modal-body {
  white-space: pre-wrap !important; /* Preserve line breaks and wrap text */
  word-wrap: break-word; /* Break long words that exceed the modal width */
  word-break: break-word; /* Safeguard to break long words if necessary */
  overflow-y: auto; /* Allow scrolling for long content */
  width: 100%; /* Ensure the modal body spans the width */
  max-height: 80vh; /* Optional: Limit modal height for larger content */
  padding-top: 15px; /* Adds space between the header and the content */

}