@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@700&display=swap');

.login-container {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.logo {
  width: 80%;
  max-width: 275px;
  height: auto;
  margin-bottom: 20px;
}

.form-container {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container form {
  width: 100%;
}

.input {
  width: 94%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.button.hp {
  padding: 12px 24px;
  min-width: 400px; /* Set a minimum width for the button */
  background-color: #6a0dad;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}


.google-button {
  width: 100%;
  padding: 12px;
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 16px;
}

.google-button img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.toggle-text {
  margin-top: 20px;
  font-size: 14px;
}

.toggle-link {
  color: #6a0dad;
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
}

.terms-text {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
}

.terms-link {
  color: #6a0dad;
  text-decoration: underline;
}

.custom-font {
  font-family: 'Oxanium', sans-serif;
}
.gsi-material-button {
    width: 100%;
    padding: 10px 15px; /* Less padding for a smaller height */
    background-color: white;
    color: black;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align content to the left */
    margin-bottom: 15px;
    font-size: 16px;
  }
  
  .gsi-material-button-content-wrapper {
    display: flex;
    align-items: center;
    width: 100%; /* Ensure the button's content spans the full width */
  }
  
  .gsi-material-button-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .gsi-material-button-contents {
    font-weight: bold;
    flex-grow: 1; /* Allow the text to take up remaining space */
    text-align: center; /* Center text within its space */
  }
  
  .google-icon {
    width: 24px;
    height: 24px;
  }
  