/* src/components/JobPostings.css */

/* Main container for job postings */
#job-postings {
    padding: 20px;
  }
  
  /* Flex container for alignment */
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Width classes */
  .w-full {
    width: 100%;
  }
  
  .md\:w-1\/2 {
    width: 48%; /* Slightly less than 50% to create space between sections */
  }
  
  /* Padding classes */
  .pr-4 {
    padding-right: 1rem;
  }
  
  .pl-4 {
    padding-left: 1rem;
  }
  
  /* General utility classes */
  .p-2 {
    padding: 0.5rem;
  }
  
  .border {
    border-width: 1px;
  }
  
  /* Update border color to purple */
  .border-purple {
    border-color: #6a0dad; /* Set border color to purple */
  }
  
  .rounded {
    border-radius: 0.25rem;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  
  /* Update the button to be purple */
  .bg-purple {
    background-color: #6a0dad;
  }
  
  .bg-purple:hover {
    background-color: #9b45db;
  }
  
  .text-white {
    color: #ffffff;
  }
  
  .w-auto {
    width: auto;
  }
  
  /* Table container styles */
  
  .table-container {
    margin-top: 20px;
    width: 100%;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: separate; /* Change from collapse to separate to allow rounding */
    border-spacing: 0; /* No space between cells */
    border-radius: 0.375rem; /* Rounded corners */
    overflow: hidden; /* Ensure corners remain rounded */
  }
  
  th:first-child {
    border-top-left-radius: 0.375rem; /* Top-left corner rounded */
  }
  
  th:last-child {
    border-top-right-radius: 0.375rem; /* Top-right corner rounded */
  }
  
  td:first-child {
    border-bottom-left-radius: 0.375rem; /* Bottom-left corner rounded */
  }
  
  td:last-child {
    border-bottom-right-radius: 0.375rem; /* Bottom-right corner rounded */
  }
  
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: black;
  }
  
  /* Flex container styles */
  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }
  
  .items-start {
    align-items: flex-start;
  }
  
  /* Prevent textarea resizing */
  textarea {
    resize: none;
  }
  
  /* Wrapper styles similar to UploadResume */
  .add-job-wrapper {
    background-color: #ffffff; /* Light background for visibility */
    padding: 1.5rem;
    border-radius: 0.375rem; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  /* Remove unwanted margins for alignment */
  .remove-margin {
    margin: 0;
  }
  
  /* Heading style */
  .heading-purple {
    color: #6a0dad;
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  /* Set consistent font for inputs */
  input, textarea {
    font-family: Arial, sans-serif; /* Consistent font */
    color: #000; /* Black color for readability */
    padding: 8px 13px 8px 15px; /* Add more padding on the right side (13px) */
    font-size: 14px; /* Adjust font size to make it slightly smaller */
    height: 20px; /* Set a fixed height for the input fields */
    line-height: 1.2; /* Adjust line height for better spacing */
  }
  
  /* Input and textarea purple border */
  /* Input and textarea purple border */
input.border-purple, 
textarea.border-purple {
  border: 1px solid #6a0dad; /* Purple border for consistency */
  padding: 8px 0px 8px 8px; /* Add more padding on the right side (13px) */
  font-family: Arial, sans-serif; /* Ensure consistent font */
  color: #000; /* Black color for better readability */
  font-size: 14px; /* Set font size for inputs */
}

/* Styles for the job postings table */
.table-container {
    margin-top: 20px;
    width: 100%;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Table Header Styling */
  th {
    background-color: #6a0dad; /* Purple background for the header */
    color: white; /* White text color for the header */
    padding: 8px;
    text-align: left;
  }
  
  /* Table Row Styling */
  tr {
    background-color: #f4f4f4; /* Light background for table rows */
    border-bottom: 1px solid #ddd; /* Border for each row */
  }
  
  /* Table Cell Styling */
  td {
    padding: 8px;
    color: #000; /* Dark text for readability */
    font-size: 13px; /* Adjusted font size for table cells */
  }
  
  /* Hover effect for table rows */
  tr:hover {
    background-color: #ececec; /* Slightly darker color on hover */
  }
  
  /* Adding border to the entire table */
  table, th, td {
    border: 1px solid #d1d5db;
  }

  .button {
    background-color: #6a0dad; /* Maintain the purple color */
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 8px; /* Add space between the buttons */
    width: 150px; /* Ensure buttons span the full width */
  }
  
  .button:hover {
    background-color: #9b45db;
  }

  /* Spacing for buttons inside the actions-container */
.actions-container .button {
    margin-right: 8px; /* Add margin to the right of each button */
  }
  
  .actions-container .button:last-child {
    margin-right: 0; /* Remove margin from the last button to avoid extra spacing */
  }

  /* Flexbox layout for large screens */
.flex-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping if necessary */
    justify-content: space-between; /* Add space between the sections */
  }
  
  /* Child Containers for Individual Sections */
  .upload-resume-section, 
  .add-job-section {
    background-color: #fff; /* White background */
    border-radius: 8px; /* Rounded corners for a clean look */
    padding: 20px; /* Padding inside the sections */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    width: 48%; /* Default side-by-side on larger screens */
    box-sizing: border-box; /* Include padding and border in width */
  }

  /* Define or modify the style for your heading */
.custom-heading {
  color: #6a0dad; /* Purple color */
}

  
  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .upload-resume-section,
    .add-job-section {
      width: 100%; /* Stack vertically on smaller screens */
      margin-bottom: 20px; /* Add space between the two sections */
    }
  }
  

/* Full width on smaller screens */
@media (max-width: 768px) {
    .upload-resume-section,
    .add-job-section {
      width: 100%; /* Stack vertically on smaller screens */
      margin-bottom: 20px; /* Add space between the two sections */
    }
  }
  
  /* Adjust widths on medium screens */
  @media (min-width: 769px) and (max-width: 1024px) {
    .upload-resume-section,
    .add-job-section {
      width: 48%; /* Slightly reduced width on medium screens */
    }
  }
  



  /* Add a background and box-shadow to make sections stand out */
.upload-resume-section, 
.add-job-section {
  background-color: #fff; /* White background */
  border-radius: 8px; /* Rounded corners for a clean look */
  padding: 20px; /* Padding inside the sections */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}


/* Ensure each table row is 100px in height */
#job-postings .job-table-cell {
    max-height: 100px !important;
    height: 100px !important;
    overflow-y: auto;
    padding: 20px;
    font-size: 0.85rem;
    box-sizing: border-box;
}

#job-postings .job-table-row {
    height: 100px !important;
}

  
  /* Specifically for the qualifications and responsibilities columns */
  .job-table-cell.qualifications,
  .job-table-cell.responsibilities {
    white-space: normal; /* Allow text wrapping for readability */
  }
  
/* CSS for the main container where the cover letter is displayed outside the modal */
.cover-letter-container {
  white-space: pre-wrap !important; /* Preserve line breaks and wrap text */
  word-wrap: break-word; /* Break long words that exceed the container width */
  word-break: break-word; /* Safeguard to break long words if necessary */
}

  /* Fullscreen modal to display the loading spinner */
.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2000; /* Higher than other elements */
  color: white;
  font-family: Arial, sans-serif;
}

.fullscreen-modal h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.fullscreen-modal p {
  margin-bottom: 20px;
  font-size: 1rem;
}

/* Spinner logo */
.spinner-logo {
  width: 100px;
  height: 100px;
  background-image: url('../assets/logo2.png'); /* Replace with the actual path to your logo */
  background-size: cover;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.button-tooltip-text {
  visibility: hidden;
  width: 200px; 
  background-color: #d8b4fe; 
  color: #000000;
  text-align: center;
  padding:  10px 12px;
  border-radius: 4px;
  position: absolute;
  bottom: 110%; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex; 
  justify-content: center; 
  align-items: center;
  white-space: normal;
}

.tooltip-container:hover .button-tooltip-text {
  visibility: visible;
  opacity: 1;
}

