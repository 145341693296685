.upload-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto; /* Center container horizontally */
  background-color: transparent;
  color: #6a0dad;  /* Set text color to dark for better readability */
  padding: 10px; /* Add a small padding for better alignment */
  border-radius: 8px;
  box-shadow: none;
}

.upload-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px; /* Reduce margin under the title */
}

.upload-dropzone {
  border: 2px dashed #6a0dad;
  border-radius: 8px;
  padding: 20px; /* Reduced padding for a more compact look */
  text-align: center;
  background-color: #f9f9f9; /* Light background to contrast with content */
  margin: 0; /* Remove any extra margin */
}

.upload-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-logo {
  width: 40px; /* Adjust size as needed */
  height: auto;
  margin-right: 8px; /* Space between logo and text */
}

.file-info {
  font-size: 1rem;
  color: #6a0dad;
}

.resume-info {
  text-align: center;
  margin-top: 10px; /* Slight spacing between dropzone and resume info */
}

.resume-link {
  color: #6a0dad;
  text-decoration: underline;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 15px; /* Space between dropzone and buttons */
}

.button {
  padding: 10px 20px; /* Adjust padding for compact look */
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button {
  background-color: #6a0dad;
  color: #fff;
}

.upload-button:hover {
  background-color: #9b45db;
}

.delete-button {
  background-color: #444;
  color: #fff;
}

.delete-button:hover {
  background-color: #666;
}

.error-message, .success-message {
  text-align: center;
  font-size: 0.875rem;
  margin: 10px 0; /* Consistent spacing for messages */
}



.upload-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-logo {
  width: 50px; /* Adjust the size as needed */
  height: auto;
  margin-right: 10px; /* Add some space between the logo and text */
}

.file-info {
  font-size: 1.2rem; /* Adjust font size as needed */
  color: #6a0dad; /* Set the color to match your theme */
}
