/* src/global.css */

/* Global Styles */
body {
    margin: 0;
     font-family: Arial, sans-serif; 
     background-color: black;
     color: white; 
  }

  body {
    @apply text-black;
  }
  
  /* Ensure default text color for input fields and form elements */
input, textarea, select {
    @apply text-black; /* Set text color to black */
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
  }
  
  .logo {
    width: 60%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .form-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-container form {
    width: 100%;
  }

/* Consistent Button Styles */
/* Define consistent button styles */
.button-primary {
  background-color: #6a0dad; /* Purple color consistent with login.css */
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  margin-top: 10px; /* Add margin if needed */
  width: 100%; /* Keep buttons full-width */
}

.button-primary:hover {
  background-color: #7d2fd3; /* Lighter hover effect */
}

.button-danger {
  background-color: #b02323; /* Darker shade of red */
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  margin-top: 10px;
  width: 100%;
}

.button-danger:hover {
  background-color: #d13030; /* Lighter hover effect */
}

/* Drag and Drop Area Styles */
.drag-and-drop-area {
  border: 2px dashed #6a0dad;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.drag-and-drop-area.drag-over {
  border-color: #9b45db;
}

/* Resume link styling */
.resume-link {
  color: #6a0dad;
  text-decoration: underline;
}

/* General Message Styles */
.message {
  font-size: 14px;
  margin-top: 10px;
}


  /* Add padding to the main content container */
.main-content {
    padding-left: 50px; /* Adjust this value to your preference */
    padding-right: 50px; /* Optional: Add padding to the right as well */
}
  
  .input {
    width: 94%;
    padding: 12px;
    margin-bottom: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }

/* Header CSS */
.header-container {
    width: 100%;
    background-color: black; /* Darker color for header */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .header-logo {
    width: 100px;
    height: auto;
  }
  
  .header-buttons {
    display: flex;
    align-items: center;
    margin-right: 60px; /* Add margin to move buttons to the right */
  }
  
  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-text {
    visibility: hidden;
    width: 250px;
    background-color: #d8b4fe; /* Light purple background */
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 125%; /* Position below the button */
    left: 50%;
    margin-left: -125px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  .donate-button,
  .logout-button {
    background-color: #6a0dad;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 15px;
  }
  
  .donate-button:hover,
  .logout-button:hover {
    background-color: #9b45db;
  }
  

/* Container for the Terms of Service page */
.tos-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: black; /* Light background for better readability */
    color: white; /* Dark text for contrast */
    line-height: 1.6;
  }
  
  /* Logo wrapper - Center the logo horizontally */
.logo-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Add space between the logo and title */
  }
  
  /* Logo styling - Adjust the size */
  .logo {
    width: 50px; /* Adjust this value to the desired width */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the logo fits within the container */
  }
  
  /* Title for Terms of Service */
  .tos-title {
    text-align: center;
    font-size: 2.5rem;
    margin-top: 40px; /* Reduce space above the title since logo is centered */
    margin-bottom: 20px;
    font-weight: bold;
  }
  .tos-title-small {
    font-size: 1.5rem; /* Adjust the size as needed */
  }
  
  /* Main content text */
  .tos-content {
    text-align: justify;
    font-size: 1rem;
  }
  
/* Add this to global.css */

/* Upload Resume Container */

.upload-resume-container {
    padding: 24px;
    background-color: #1a1a1a; /* Darker background to blend with the theme */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto; /* Center the container on the page */
    color: white; /* Ensure text color matches the theme */
  }
  
  .upload-resume-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  


  .resume-link {
    color: #6a0dad;
    font-weight: bold;
  }
  
  .resume-button {
    padding: 12px 16px;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 12px;
    border: none;
  }
  
  .resume-button-upload {
    background-color: #6a0dad;
    color: white;
  }
  
  .resume-button-upload:hover {
    background-color: #7d2fd3;
  }
  
  .resume-button-delete {
    background-color: #e53e3e;
    color: white;
  }
  
  .resume-button-delete:hover {
    background-color: #f56565;
  }
  
  .resume-input {
    padding: 12px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    color: black; /* Ensure text is visible */
  }
  
  .message {
    margin-top: 16px;
    text-align: center;
  }
  
  .text-red-600 {
    color: #f56565;
  }
  
  .text-green-600 {
    color: #48bb78;
  }
  